<template>
  <div id="app">
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => {
    return {
      show: false,
      path: ''
    }
  },
  created () {
    this.$store.dispatch('tryAutoSignIn')
    //this.path = JSON.stringify(this.$route.path)
    //this.show = this.path.includes('dash') || false
  }
}
</script>

<style lang="scss">
@import "@/styles/master";

body{
  background: $curadr-charcoal;
}
</style>