import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import './styles/master.scss'


import axios from 'axios';
import Vuelidate from 'vuelidate';


Vue.config.productionTip = false

Vue.prototype.$http = axios;


Vue.use(Vuelidate);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')