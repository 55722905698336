<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-2">
          <div class="logo">
            <a href="/questionaires">
              <img src="../gfx/logo.svg" />
            </a>
          </div>
        </div>
        <div class="col-10 member">
          <Component_Dashboard_Member />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Component_Dashboard_Member from './Member';

export default {
  name: "Header_component",
  components:{
      Component_Dashboard_Member
  }
};
</script>

<style lang="scss">
.header {
  background: white;
  min-height: 7rem;
  border-bottom: 0.1rem solid rgba($curadr-charcoal, 0.15);

    display: flex;
    flex-direction: column;
    justify-content: center;

    .member{

        display: flex;
        flex-direction: column;
        justify-content: center;

    }

  .logo {
    img {
      max-height: 4.6rem;
    }
  }
}
</style>