<template>
    <div>
        <Component_Signin/>
    </div>
</template>

<script>
import Component_Signin from "@/components/Signin"

export default {
    components:{
        Component_Signin
    }
}
</script>