<template>
  <div v-if="dataObj.personalInformation">
    <div class="questionaire" ref="questionaire">
      <div class="section">
        <div class="title">Personal information</div>
        <div class="content">
          <div>
            <span>Name</span
            ><strong>{{ dataObj.personalInformation.nickname }}</strong>
          </div>
          <div v-if="dataObj.personalInformation.dob">
            <span>Date of birth</span
            ><strong>{{ dataObj.personalInformation.dob }}</strong>
          </div>
          <div>
            <span>Email address</span
            ><strong
              ><a :href="`mailto:${dataObj.metadata.curator_email}`">{{
                dataObj.metadata.curator_email
              }}</a></strong
            >
          </div>
        </div>
      </div>

      <div class="section">
        <div class="title">Background</div>
        <div class="content">
          <div>
            <span>Academic/Medical Speciality</span
            ><strong>{{ dataObj.experience.speciality }}</strong>
          </div>
          <div>
            <span>Speciality details</span
            ><strong>{{ dataObj.experience.specifySpeciality }}</strong>
          </div>

          <div>
            <span>Institutional affiliation</span
            ><strong>{{ dataObj.experience.institutionalAffiliation }}</strong>
          </div>
          <div>
            <span>Institutional country</span
            ><strong>{{ dataObj.experience.institutionalCountry }}</strong>
          </div>

          <div>
            <span>Training level</span
            ><strong>{{ dataObj.experience.trainingLevel }}</strong>
          </div>
          <div>
            <span>Job title</span
            ><strong>{{ dataObj.experience.jobTitle }}</strong>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="title">Experience</div>
        <div class="content">
          <div>
            <span>Peer-review status</span
            ><strong>{{ dataObj.reviewStatus.peerReviewStatus }}</strong>
          </div>
          <div>
            <span>Editorial review status</span
            ><strong>{{ dataObj.reviewStatus.editorialReviewStatus }}</strong>
          </div>
          <div>
            <span>Meta/Sys Rev. Involvement</span
            ><strong>{{ dataObj.reviewStatus.metasysInvolvement }}</strong>
          </div>
          <div v-if="dataObj.reviewStatus.orcidId">
            <span>ORCID ID</span
            ><strong
              ><a
                target="_blank"
                :href="`https://orcid.org/${dataObj.reviewStatus.orcidId}`"
                >https://orcid.org/{{ dataObj.reviewStatus.orcidId }}</a
              ></strong
            >
          </div>
          <div v-if="dataObj.reviewStatus.academicProfileUrl">
            <span>Academic profile URL</span
            ><strong>{{ dataObj.reviewStatus.academicProfileUrl }}</strong>
          </div>
          <div v-if="dataObj.reviewStatus.linkedinUrl">
            <span>LinkedIn URL</span
            ><strong>{{ dataObj.reviewStatus.linkedinUrl }}</strong>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="title">Meta data</div>
        <div class="content">
          <div>
            <span>Submitted</span
            ><strong>{{ new Date(dataObj.metadata.date_created) }}</strong>
          </div>
          <div>
            <span>Ip-address</span
            ><strong>{{ dataObj.metadata.curator_ip }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="buttonHolder">
        <button @click="reject(dataObj.id, dataObj.personalInformation.nickname)" class="button secondary warning">Reject</button><button @click="approve(dataObj.id, dataObj.personalInformation.nickname)"  class="button primary">Approve</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Component_Questionaire",
  props: ["dataObj"],
  methods: {
    reject(paramId, paramNickname) {
      if (
        confirm(
          `Are you sure you want to REJECT the application for ${paramNickname}?`
        )
      ) {
        this.$emit("rejected", paramId);
      }
    },
    approve(paramId, paramNickname) {
      if (
        confirm(
          `Are you sure you want to APPROVE the application for ${paramNickname}?`
        )
      ) {
        this.$emit("approved", paramId);
      }
    },
  },
  mounted(){
      
  }
};
</script>

<style lang="scss">
.questionaire {
  margin: 2rem;
  border: solid 0.1rem rgba($curadr-charcoal, 0.1);
  .section {
    .title {
      padding: 1rem 2rem;
      background: rgba($curadr-charcoal, 0.05);
      font-weight: 400;
    }
    .content {
      padding: 3rem 2rem;
      > div {
        padding: 0.5rem 0;
      }
      > div:last-child {
        padding-bottom: 4rem;
      }
      span {
        display: inline-block;
        min-width: 22rem;
      }
    }
  }


}

  .buttonHolder{
      display: flex;
      flex-direction: row;
      //align-items: center;
      justify-content: center;

      margin: 3rem;

      button{
          //display: flex;
          //margin: 3rem auto;
          &:last-child{
              margin-left: 3rem;
          }
      }
  }
</style>