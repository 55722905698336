<template>
  <transition name="fade">
    <div class="modal" v-show="show"  :class="{'show': show}" ref="modal">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-9">
            <transition name="slide" >
            <div class="modal__dialog" v-show="show">
              <div class="modal__header">
                <slot name="header" />

                <button
                  type="button"
                  class="modal__close"
                  @click="closeModal()"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                    <path
                      fill="currentColor"
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    ></path>
                  </svg>
                </button>
              </div>

              <div class="modal__body">
                <slot name="body"></slot>
              </div>

              <div class="modal__footer">
                <slot name="footer" />
              </div>
            </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Component_Modal",
  props: ["activeObj"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
      activeObj: function(){ //Scrolling questionaire to top when opened
        this.$nextTick(() => {
              this.$refs.modal.scrollTop = 0
        })
      }
  },
  mounted: function () {
    if (process.isClient) {
      document.addEventListener("keydown", (e) => {
        if (this.show && e.keyCode == 27) {
          this.closeModal();
        }
      });
    }
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      //this.$data.dataObj = paramDataObj;
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>


<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;








  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.8);
    z-index: 1;
  }
  &__dialog {
    position: relative;
    //width: 600px;
    background-color: #ffffff;
    box-shadow: 0 0.3rem 1rem -1rem rgba(12, 12, 12, 0.9);
    border: 1px solid rgba(12, 12, 12, 0.1);
    //border-radius: 5px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    z-index: 2;
    @media screen and (max-width: 992px) {
      //width: 90%;
    }
  }
  &__close {
    width: 30px;
    height: 30px;
    transition: all 0.3s;
    &:hover {
      transform: rotate(90deg);
      transition: all 0.5s;
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px 10px 10px 10px;
    button {
      border: none;
      background: none;
      outline: none;
    }
  }
  &__body {
    //padding: 10px 20px 10px;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;

}



.slide-enter-active,
.slide-leave-active {

      opacity: 1;
      transform: translateY(0);
      transition: all .2s ease-out;

}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(50vh);
    transition: all .1s;

}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9 {
  &::before {
    padding-top: percentage(9 / 21);
  }
}

.embed-responsive-16by9 {
  &::before {
    padding-top: percentage(9 / 16);
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}

.embed-responsive-1by1 {
  &::before {
    padding-top: percentage(1 / 1);
  }
}
</style>
