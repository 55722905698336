<template>

    <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 text-center text-lg-start">
              <p><b>CURADR.</b>&nbsp;ROOTED IN SCIENCE. ENHANCED BY PEOPLE.</p>
            </div>

            <div class="col-12 col-lg-6 text-center text-lg-end">
              © {{year}} CURADR. All rights reserved.
            </div>
          </div>
    </div>

</template>



<script>


export default {
  name: "Component_Footer_Copyright",
  props:[
    'propFullWidth'
  ],
  methods: {
  },
  computed:{
    year: () =>{
      var thisYear = new Date();
      return thisYear.getFullYear();
    }
  }
}
</script>


<style lang="scss">


.copyright{
  width: 100%;
  margin: 0;
    padding: 2rem 0 1rem 0;
    //border-top: .1rem solid rgba($curadr-charcoal, .05);

    font-size: 1.2rem;
    line-height: 2rem;
}
</style>