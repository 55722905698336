<template>
  <div v-if="this.$store.state.auth.authenticated">
    <Header_component />
    <Component_Datatable />
    <Component_Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header_component from "@/components/Header.vue";
import Component_Datatable from "@/components/Datatable.vue";
import Component_Footer from "@/components/Footer";

export default {
  name: "Index",
  components: {
    Header_component,
    Component_Datatable,
    Component_Footer,
  },
  mounted() {
    if (!this.$store.state.auth.authenticated) {
      this.$router.push("/");
    }
  },
};
</script>