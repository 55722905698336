<template>
  <div class="login">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10 col-md-9 col-lg-6">
          <div class="wrapper">
            <div class="card">
              <h3>Curadr</h3>
              <div class="intro">Sign in to the Curadr backoffice</div>
              <div class="form">
                <form @submit.prevent="submit">
                  <label
                    >Email *
                    <input
                      type="text"
                      name="email"
                      v-model="$v.formObj.email.$model"
                      :disabled="loading"
                      placeholder="Please provide your email address"
                      class="form-control"
                      :class="{ error: $v.formObj.email.$error }"
                    />
                  </label>
                  <div
                    class="error"
                    v-if="$v.formObj.email.$dirty && !$v.formObj.email.required"
                  >
                    Email is required.
                  </div>
                  <div
                    class="error"
                    v-if="$v.formObj.email.$dirty && !$v.formObj.email.email"
                  >
                    Please provide a valid email
                  </div>

                  <label
                    >Password *
                    <input
                      type="password"
                      name="password"
                      v-model="$v.formObj.password.$model"
                      :disabled="loading"
                      placeholder="Please provide a password"
                      class="form-control"
                      :class="{ error: $v.formObj.password.$error }"
                    />
                  </label>
                  <div
                    class="error"
                    v-if="
                      $v.formObj.password.$dirty &&
                      !$v.formObj.password.required
                    "
                  >
                    Password is required.
                  </div>

                  <div class="errorMsg" v-if="errorMsg">
                    {{ errorMsg }}
                  </div>

                  <div class="formButtons">
                    <button
                      class="button primary submit"
                      :class="{ disabled: loading }"
                      :disabled="loading"
                      @click="validateSignin"
                    >
                      <span class="spinner" v-if="loading"
                        ><scale-loader
                          :loading="loading"
                          :color="color"
                          :width="width"
                          :height="height"
                        ></scale-loader></span
                      ><span class="btnlabel">{{ SubmitLabel }}</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "Component_Signin",
  components: {
    ScaleLoader,
  },
  data() {
    return {
      user: null,
      authState: null,
      unsubscribeAuth: null,
      userAttributes: null,
      color: "white",
      width: "3px",
      height: "14px",
      loading: false,
      SubmitLabel: "Sign in",
      trySubmitting: false,
      formObj: {
        email: null,
        password: null,
      },
      errorMsg: null,
    };
  },
  validations() {
    return {
      formObj: {
        email: { required, email },
        password: { required },
      },
    };
  },
  methods: {
    validateSignin: function () {
      this.$data.trySubmitting = true;
    },
    async submit() {
      this.$data.errorMsg = null;

      if (!this.$v.$invalid) {
        this.$data.loading = true;
        this.$data.SubmitLabel = "Signing in";


      var authData = {
        Username: this.formObj.email,
        Password: this.formObj.password
      }
      this.$store.dispatch('signIn', authData);



      } else {
        this.$data.errorMsg = "Please fill out both fields";
        this.$data.loading = false;
        this.$data.SubmitLabel = "Sign in";
      }
    },
  },
  computed: {
    errcode: function () {
      return this.$store.state.auth.errcode
    }
  },
  watch: {
    errcode () {
      this.$data.loading = false;
      this.loader = 'loading'
      const l = this.loader
      this[l] = !this[l]
      console.log('watched error code: ' + this.errcode)
      if (this.errcode !== '') {
        this.$data.SubmitLabel = "Sign in";
        if (this.errcode === '"NotAuthorizedException"') {
          this.errorMsg = 'Incorrect username or password'
        } else if (this.errcode === '"UserNotFoundException"') {
          this.errorMsg = 'User not found'
        } else if (this.errcode === '"UserNotConfirmedException"') {
          this.$store.commit('setUsername', this.email)
          this.resend = true
          this.errorMsg = 'User registration not confirmed'
        } else if (this.errcode === '"LimitExceededException"') {
          this.errorMsg = 'Attempt limit exceeded, please try after some time'
        } else {
          this.errorMsg = 'An error has occured!'
        }
        this.showerr = true
      } else {
        this.showerr = false
      }
      this[l] = false
      this.loader = null
    }
  }
};
</script>

<style lang="scss">
.login {
  .wrapper {
    display: flex; /* establish flex container */
    flex-direction: column; /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */
    min-height: 100vh;
    position: relative;
  }
  .card {
    position: relative;
    //display: flex;
    width: 100%;
    background: white;
    padding: 3rem;
    box-shadow: 0 0 1rem rgba($curadr_charcoal, 0.1);
    border-radius: 0.4rem;

    //display: flex; /* establish flex container */
    //flex-direction: column; /* make main axis vertical */
    //justify-content: center; /* center items vertically, in this case */
    //align-items: center; /* center items horizontally, in this case */
  }

  button.submit {
    display: flex; /* establish flex container */
    flex-direction: row; /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */

    padding-top: 1.4rem;
    padding-bottom: 1.4rem;

    margin-top: 3rem;

    span {
      display: flex;
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
    .spinner {
      margin-right: 1rem;
    }
    .btnlabel {
    }
  }
}
</style>