<template>
  <div class="container">


      <Component_Modal ref="modalHandle" name="" :activeObj="activeObj">
        <template v-slot:body v-bind:activeObj="activeObj"> 
            <Component_Questionaire :dataObj="activeObj" @rejected="reject" @approved="approve"/>
        </template>
        </Component_Modal>

    <div class="row stats">
      <div class="col-12 col-lg-4">
          <Component_Stat :propTitle="this.$data.numberOfCuratedJobs + ' jobs sucessfully'" propBody="Curated since inception" :dataIsLoading="initDataIsLoading">
            <img src="@/gfx/layers.svg"/>
           </Component_Stat>
      </div>

      <div class="col-12 col-lg-4">
          <Component_Stat :propTitle="this.$data.numberOfRegisteredUsers + ' curators'" propBody="Registred in the system" :dataIsLoading="initDataIsLoading">
            <img src="@/gfx/user.svg"/>
           </Component_Stat>
      </div>

      <!--<div class="col-3">
          <Component_Stat propTitle="33 applications" propBody="Rejected after screening">
            <img src="@/gfx/stop.svg"/>
           </Component_Stat>
      </div>-->

      <div class="col-12 col-lg-4">
          <Component_Stat :propTitle="this.$data.questionaires.length + ' applications'" propBody="Currently awaiting approval" :dataIsLoading="dataIsLoading">
            <img src="@/gfx/visibility.svg"/>
           </Component_Stat>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tableWrapper">



          <!--<div class="filter-bar ui basic segment grid">
      <div class="ui form">
        <div class="inline field">
          <label>Search for:</label>
          <input
            type="text"
            v-model="filterText"
            class="three wide column"
            @input="doFilter"
            placeholder="first name, last name, or email"
          />
          <button class="ui primary button" @click="doFilter">Go</button>
          <button class="ui button" @click="resetFilter">Reset</button>
        </div>
      </div>
    </div>-->
<div class="tableHeader">Applications awaiting review</div>
    <div class="tabledataLoading" v-if="dataIsLoading"><scale-loader  color="#00B989" width="3px" height="14px"></scale-loader><div class="label">Loading data</div></div>
    <div class="tableHolderResponsive">
          <vuetable
            ref="vuetable"
            :api-mode="false"
            :per-page="perPage"
            :data-manager="dataManager"
            pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData"
            :fields="[
              {
                name: 'personalInformation.firstname',
                title: 'First name',
              },
              {
                name: 'personalInformation.lastname',
                title: 'Last name',
              },
              {
                name: 'metadata.curator_email',
                title: 'Email',
              },
              {
                name: 'metadata.date_created_formatted',
                title: 'Submitted',
              },
              
            ]"
            :css="css.table"
            @vuetable:row-clicked="clicked"
          >
          
          </vuetable>
    </div>

          <vuetable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import _ from "lodash";
import CssForBootstrap4 from "./../VuetableCssBootstrap4";

import Component_Stat from "../components/Stat";
import Component_Modal from "../components/Modal";
import Component_Questionaire from "../components/Questionaire";

import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

//import axios from "axios"
//import aws4 from "aws4"

export default {
  name: "Component_Datatable",
  components: {
    Vuetable,
    VuetablePagination,
    //VuetablePaginationInfo,
    //Component_Dashboard_Card,
    Component_Stat,
    Component_Modal,
    Component_Questionaire,
    ScaleLoader
  },
  async mounted() {

    this.$data.dataIsLoading = true;
    this.$http
      .get(
        "https://aexapi26b0.execute-api.eu-west-1.amazonaws.com/dev/backofficegetquestionaires",
        {
          headers: { 
            'Authorization': `${this.$store.state.auth.tokens.idToken}`,
          },
        }
      )
      .then((response) => {
        this.$data.questionairesBase = response.data;
        this.$data.questionaires = response.data;
        this.$data.dataIsLoading = false;
      });

    
    this.initData();
    


  },
  data() {
    return {
      css: CssForBootstrap4,
      perPage: 10,
      questionaires: [],
      questionairesBase: [],
      filterText: "",
      activeObj:"",
      initDataIsLoading: true,
      numberOfRegisteredUsers: 0,
      numberOfCuratedJobs: 0,
      dataIsLoading: true
    };
  },
  watch: {
    questionaires() {
      //console.log('WATCH', this.$data)
      this.$refs.vuetable.refresh();
    },
  },
  methods: {
    initData(){
      this.$data.initDataIsLoading = true;
      this.$http
      .get(
        "https://aexapi26b0.execute-api.eu-west-1.amazonaws.com/dev/backofficeinit",
        {
          headers: { 
            'Authorization': `${this.$store.state.auth.tokens.idToken}`,
          }
        }
      )
      .then((response) => {
        this.$data.numberOfRegisteredUsers = response.data.registeredUsers;
        this.$data.numberOfCuratedJobs = response.data.completedCurationJobs;
        this.$data.initDataIsLoading = false;
      });
    },
    
    reject(questionaireId){
      this.$data.dataIsLoading = true;
      this.$refs.modalHandle.closeModal();
      this.$http
      .post(
        "https://aexapi26b0.execute-api.eu-west-1.amazonaws.com/dev/backofficeprocessquestionaire",
          {
            'userId': questionaireId,
            'application_status': 'rejected'
          },
          {
            headers: {'Authorization': `${this.$store.state.auth.tokens.idToken}`}
          }
      )
      .then((response) => {
        console.log(response, this.$data);
        for (let index = 0; index < this.$data.questionaires.length; index++) {
            if(this.$data.questionaires[index].id === questionaireId){
                this.$data.questionaires.splice(index, 1);           
            }
        }
        this.$data.dataIsLoading = false;
        this.initData();
      });
        this.$refs.modalHandle.closeModal();
    },
    approve(questionaireId){
      this.$data.dataIsLoading = true;
      this.$refs.modalHandle.closeModal();
      this.$http
      .post(
        "https://aexapi26b0.execute-api.eu-west-1.amazonaws.com/dev/backofficeprocessquestionaire",
          {
            'userId': questionaireId,
            'application_status': 'approved'
          },
          {
            headers: {'Authorization': `${this.$store.state.auth.tokens.idToken}`}
          }
      )
      .then((response) => {
        console.log(response, this.$data);
        for (let index = 0; index < this.$data.questionaires.length; index++) {
            if(this.$data.questionaires[index].id === questionaireId){
                this.$data.questionaires.splice(index, 1);           
            }
        }
        this.$data.dataIsLoading = false;
      });

        
    },
    doFilter() {
      if (this.$data.filterText.length > 0) {
        var filteredArray = this.questionairesBase.filter(
          (questionaireItem) => {
            var searchObjString = `${questionaireItem.personalInformation.firstname}${questionaireItem.personalInformation.lastname}${questionaireItem["email"]}`;
            return (
              searchObjString.toLowerCase().indexOf(this.$data.filterText) > -1
            );
          }
        );

        if (filteredArray.length > 0) {
          this.$data.questionaires = filteredArray;
        } else {
          this.$refs.vuetable.resetData();
        }
      } else {
        this.$data.questionaires = this.$data.questionairesBase;
      }
    },
    resetFilter() {
      this.filterText = "";
      this.$data.questionaires = this.$data.questionairesBase;
    },

    removeItem() {
      this.$data.questionaires[1].status = "approved";
      //this.$data.questionaires.pop(0);
      console.log(this.$data.questionaires);
    },
    clicked(e) {
      //console.log("clicked");
      this.$data.activeObj=e;
      //this.$refs.modalHandle.$data.dataObj = e;
      this.$refs.modalHandle.openModal();
      //console.log(this.$refs.modalHandle.$el);
      this.$refs.modalHandle.$el.scrollTo(0, 0);



    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      //if (this.$data.questionaires.length < 1) return;

      let local = this.$data.questionaires;

      local.forEach(questionaire => {
          let theDate = new Date(questionaire.metadata.date_created);
          let formattedDate = theDate.toLocaleDateString('en-GB');
          let formattedTime = theDate.toLocaleTimeString();
          questionaire.metadata['date_created_formatted'] = `${formattedDate}  ${formattedTime}`;
      });


      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        //console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );

      //console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;
      //console.log(local, from, to);
      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
  },
};
</script>


<style lang="scss">
@import "~/node_modules/bootstrap/scss/functions";
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins";

.tableWrapper {
  margin-top: 10rem;
  position: relative;
}

.tableHolderResponsive{
  overflow-x: scroll;
}


.tableHeader{
    font-size: 2rem;
    font-weight: 400;
    padding: 0 0 2rem .5rem;
}
table {
  width: 100%;
  border: 0.1rem solid rgba($curadr-charcoal, 0.1);
  position: relative;
  /*&::before{
      content: '';
      display: block;
      width: 100%;
      height: .3rem;
      background: $curadr-green-light;
      position: absolute;
      top: 0;
      border-radius: .4rem;
  }*/
}


.tabledataLoading{
  height: 100%;
  background: rgba(white, 1);
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 0.1rem solid rgba($curadr-charcoal, 0.1);
  border-radius: 0.4rem;

  .label{}
}


.vuetable-body-wrapper, .vuetable{
  //min-height: 22.8rem;
}
.vuetable {
  td:nth-child(1) {
    width: 25%;
  }
  td:nth-child(2) {
    width: 25%;
  }
  td:nth-child(3) {
    width: 30%;
  }
  td:nth-child(4) {
    width: 20%;
  }
  td:nth-child(5) {
    width: 10%;
  }

  th {
    padding: 2.6rem 1rem;
    font-weight: 400;
    background: white;
    border-bottom: 0.1rem solid rgba($curadr-charcoal, 0.1);
    position: relative;
  }
  th {
    border-top-left-radius: 0.4rem;
  }
  th:first-child {
    border-top-left-radius: 0.4rem;
    padding-left: 3rem;
  }
  th:last-child {
    border-top-right-radius: 0.4rem;
  }

  tbody tr {
    border-bottom: 0.1rem solid rgba($curadr-charcoal, 0.1);
    transition: all 0.1s;

    td:first-child {
      padding-left: 3rem;
    }
    &:hover {
      cursor: pointer;
      transition: all 0.2s;
      background: white;

      td:first-child {
        border-left: 0.3rem solid $curadr-green-light;
      }
    }
  }
  td {
    //border: .1rem solid rgba($curadr-charcoal, .1);
    padding: 1.6rem 1rem;
  }
}

.pagination {
  float: right;
  display: inline-flex;
  vertical-align: middle;
  margin-top: 3rem;
  .item {
    min-width: 4rem;
    min-height: 4rem;
    display: table-cell;
    display: flex;
    align-items: center;
    justify-content: center;

    //padding: .6rem .5rem;
    
    text-align: center;
    //display: inline-block;
    align-items: center;
    border: 0.1rem solid rgba($curadr-charcoal, 0.1);

    color: rgba($curadr-charcoal, 0.5);

    cursor: pointer;

    &.active{
        color: rgba($curadr-charcoal, 1);
        background: white;
    }

    &:first-child:not(.icon){
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }

    

    &.icon{
        display: none;
    }
  }
}

.stats{
  position: relative;
  padding-top: 4rem;

  &>div{
    position: relative;
    margin-top: 2rem;
  }
}



@include media-breakpoint-down(lg) {
  .vuetable thead tr th:first-child, .vuetable tbody tr td:first-child {
    padding-left: 1rem;
  }
}

</style>