import Vue from 'vue'
import VueRouter from 'vue-router'



//import store from '../store'

import Questionaires from '../views/Questionaires.vue'
import Signin from '../views/Signin.vue'




Vue.use(VueRouter)



const routes = [
  {
    path: '/questionaires',
    name: 'Questionaires',
    component: Questionaires
  },
  {
    path: '/',
    name: 'Signin',
    component: Signin
  }
  
  /*,
  {
    path: '/questionaires',
    name: 'Questionaires',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Questionaires.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



/*router.beforeEach((to, from, next) => {
  //if(to.path != '/' && !store.state.auth.authenticated)  next({name: 'Signin'})
  //else next()
  console.log(store, to, from)
  

  if(to.path == '/questionaires'){
    console.log('questionaire')
  }

  next()
})*/


export default router


