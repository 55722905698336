<template>
  <div class="comp_member">
    <div class="dropdown" @click="toggleVisibility">
      <div class="name">
        <span v-if="this.$store.state.auth.authenticated">{{ this.$store.state.auth.username }}</span>
      </div>
      <div class="avatar">
        <Component_Dashboard_Avatar
          propValue="MN"
          propColor="#fff"
          propBgColor="#00B989"
        />
      </div>
    </div>
    <div class="dropdown_content" v-if="this.$data.isVisible">
      <div class="list_item" @click="logout()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M255.15 468.625H63.787c-11.737 0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262 21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85 255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619 63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262 0-11.758-9.504-21.262-21.262-21.262z"
          />
          <path
            d="M505.664 240.861L376.388 113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041 14.926-6.124l129.276-127.575A21.303 21.303 0 00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"
          />
        </svg>
        <span>Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
import Component_Dashboard_Avatar from "./Avatar.vue";

export default {
  components: {
    Component_Dashboard_Avatar,
  },

  name: "Component_Dashboard_Member",

  props: ["propValue", "propBgColor", "propColor"],

  data: () => ({
    isVisible: false,
    userAttributes: null,
  }),

  mounted() {
    document.addEventListener("click", this.closeDropdown);
    document.addEventListener("keyup", this.escKeyDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
    document.removeEventListener("keyup", this.escKeyDropdown);
  },

  created() {},

  methods: {
    logout() {
      /*const constlogout = await Auth.signOut()
      .then(()=>{
          this.$router.push('/members')
        console.log('Logged out')
      });*/

      this.$store.dispatch('signOut')

      
    },

    escKeyDropdown: function (evt) {
      //let thisComponent = this;
      if (evt.keyCode === 27) {
        this.$data.isVisible = false;
      }
    },
    closeDropdown: function (e) {
      if (this.$data.isVisible && !this.$el.contains(e.target)) {
        console.log("close", e.target);
        this.$data.isVisible = false;
      }
    },
    toggleVisibility: function () {
      //let thisComponent = this;
      this.$data.isVisible = !this.$data.isVisible;
      //console.log(this.$data.isVisible);
      /*if(this.$data.isVisible){
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                thisComponent.closeDropdown();
            }
        });
      }else{
        document.removeEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                thisComponent.closeDropdown();
            }
        });
      }*/
    },
  },
};
</script>
<style lang="scss">


.comp_member {
  z-index: 2;
  position: relative;
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  align-items: flex-end; /* center items vertically, in this case */

  //margin-top: 3rem;

  user-select: none;

  .dropdown_content {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 20rem;
    background: white;
    top: 80%;
    box-shadow: 0rem 0rem 1rem 0.1rem rgba($curadr-charcoal, 0.1);
    border-radius: 0.25rem;

    .list_item {
      padding: 1rem 2rem;
      width: 100%;
      span {
        display: inline-block;
        margin-left: 1rem;
      }
      svg {
        width: 1.6rem;
      }

      &:hover {
        cursor: pointer;
        font-weight: 400;
        //background: rgba($curadr-green-light, .2);
      }
    }
  }

  .dropdown {
    position: relative;
    display: flex; /* establish flex container */
    flex-direction: row; /* make main axis vertical */
    justify-content: flex-end; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */

    &:after {
      display: inline-block;
      margin-left: 0.5em;
      vertical-align: middle;
      content: "";
      margin-top: -0.28em;
      width: 0.42em;
      height: 0.42em;
      border: 1px solid;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:hover {
      cursor: pointer;
      .name {
        opacity: 0.5;
      }
      &:after {
        opacity: 0.5;
      }
    }
  }
  .name {
  }

  .avatar {
    width: 3rem;
    height: 3rem;

    margin: 0 0.3rem 0 1rem;
  }
}
</style>



