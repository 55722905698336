<template>

  <div class="stat">
    <div class="wrapper">
      <div class="statdataLoading" v-show="$props.dataIsLoading">
        <scale-loader
          :loading="$props.dataIsLoading"
          color="#00B989"
          width="3px"
          height="14px"
        ></scale-loader>
      </div>
      <div class="icon"><slot /></div>
      <div class="content">
        <div class="title">{{ $props.propTitle }}</div>
        <div class="body">{{ $props.propBody }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

//import axios from "axios"
//import aws4 from "aws4"

export default {
  name: "Component_Stat",
  props: ["dataIsLoading", "propTitle", "propBody", "propIcon"],
  components: {
    ScaleLoader,
  },
};
</script>
<style lang="scss">


.stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;
 
        position: relative;
    .wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        padding: 2.6rem 4rem;
        
    }
  

  background: white;
  

  height: 100%;

  border-radius: 1rem;
  border: 0.1rem solid rgba($curadr-charcoal, 0.1);

  //margin-top: 4rem;

  position: relative;


  .icon {
    display: flex;
    width: 7rem;

    img {
      max-width: 5rem;
      height: auto;
    }

    &.rejected {
      img {
        max-width: 4rem;
      }
    }
  }
  .content {
    //display: flex;
    .title {
      font-size: 2rem;
      line-height: 1.2;
      font-weight: 400;
    }
    .body {
      font-size: 1.4rem;
      font-weight: 100;
      opacity: 0.8;
    }
  }

  .statdataLoading {
    height: 100%;
    background: rgba(white, 1);
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: 1rem;

    top: 0;
    left: 0;

    .label {
    }
  }
}
</style>