var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('Component_Modal',{ref:"modalHandle",attrs:{"name":"","activeObj":_vm.activeObj},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('Component_Questionaire',{attrs:{"dataObj":_vm.activeObj},on:{"rejected":_vm.reject,"approved":_vm.approve}})]},proxy:true}])}),_c('div',{staticClass:"row stats"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('Component_Stat',{attrs:{"propTitle":this.$data.numberOfCuratedJobs + ' jobs sucessfully',"propBody":"Curated since inception","dataIsLoading":_vm.initDataIsLoading}},[_c('img',{attrs:{"src":require("@/gfx/layers.svg")}})])],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('Component_Stat',{attrs:{"propTitle":this.$data.numberOfRegisteredUsers + ' curators',"propBody":"Registred in the system","dataIsLoading":_vm.initDataIsLoading}},[_c('img',{attrs:{"src":require("@/gfx/user.svg")}})])],1),_c('div',{staticClass:"col-12 col-lg-4"},[_c('Component_Stat',{attrs:{"propTitle":this.$data.questionaires.length + ' applications',"propBody":"Currently awaiting approval","dataIsLoading":_vm.dataIsLoading}},[_c('img',{attrs:{"src":require("@/gfx/visibility.svg")}})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tableWrapper"},[_c('div',{staticClass:"tableHeader"},[_vm._v("Applications awaiting review")]),(_vm.dataIsLoading)?_c('div',{staticClass:"tabledataLoading"},[_c('scale-loader',{attrs:{"color":"#00B989","width":"3px","height":"14px"}}),_c('div',{staticClass:"label"},[_vm._v("Loading data")])],1):_vm._e(),_c('div',{staticClass:"tableHolderResponsive"},[_c('vuetable',{ref:"vuetable",attrs:{"api-mode":false,"per-page":_vm.perPage,"data-manager":_vm.dataManager,"pagination-path":"pagination","fields":[
              {
                name: 'personalInformation.firstname',
                title: 'First name',
              },
              {
                name: 'personalInformation.lastname',
                title: 'Last name',
              },
              {
                name: 'metadata.curator_email',
                title: 'Email',
              },
              {
                name: 'metadata.date_created_formatted',
                title: 'Submitted',
              } ],"css":_vm.css.table},on:{"vuetable:pagination-data":_vm.onPaginationData,"vuetable:row-clicked":_vm.clicked}})],1),_c('vuetable-pagination',{ref:"pagination",on:{"vuetable-pagination:change-page":_vm.onChangePage}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }