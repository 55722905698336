<template>
    <div class="comp_footer">
        <footer>
            <div class="container-fluid footer">
                <div class="row justify-content-center">
                <div class="col-12 text-center copyright">
                    <Component_Footer_Copyright/>
                </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import Component_Footer_Copyright from "./Footer_copyright";



export default {
  name: "Component_Footer",
  components: {
        Component_Footer_Copyright
  },
  props:[

  ],
  data: () => ({
    
  }),
  mounted () { 

  }
};
</script>
<style lang="scss">
@import "~/node_modules/bootstrap/scss/functions";
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins";



.comp_footer {

    margin-top: 14rem;

     background-image: url(~@/gfx/dashboard_particles.jpg);
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-position-y: 0;
        
    .footer {
       
        min-height: 30rem;

        position: relative;

        

        .copyright{
            border-top: .1rem solid rgba($curadr-charcoal, .1);
            position: absolute;
            bottom: 0;
            background: $curadr-grey-light
        }
    }
}

@include media-breakpoint-up(lg) {
    .comp_member_footer {
        margin-top: 10rem;
    }
}
</style>