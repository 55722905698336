<template>
  <div
    class="comp_avatar"
    :style="{
      'background-color': this.$props.propBgColor,
      color: this.$props.propColor,
    }"
  >
    <div><img src="@/gfx/avatar.svg"></div>
  </div>
</template>

<script>
export default {
  name: "Component_Dashboard_Avatar",

  props: ["propValue", "propBgColor", "propColor"],
};
</script>
<style lang="scss">
.comp_avatar {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */

  border-radius: 50%;

  width: 100%;
  height: 100%;

  user-select: none;

  div {
    text-align: center;
    font-weight: 400;
    font-size: 1.8rem;

    img{
      max-width: 60%;
      height: auto;
      opacity: .8;
    }
  }
}
</style>